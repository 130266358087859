<template>
  <div class="b-cuti-form pb-5">
    <b-form @submit.prevent="doSubmit">
      <b-row no-gutters class="m-0">
        <b-col sm="12" class="p-3">
          <b-form-group label="Kantor Cabang" class="mb-3 f-13 bold" disabled>
            <b-input class="f-13" v-model="form.data.branch_name" />
          </b-form-group>
          <b-form-group label="NIK" class="mb-3 f-13 bold" disabled>
            <b-input class="f-13" v-model="form.data.nik" />
          </b-form-group>
          <b-form-group label="Jumlah" class="mb-3 f-13 bold">
            <b-input
              class="f-13"
              v-model="form.data.jml_kasbon"
              :required="!form.data.jml_kasbon"
              placeholder="Silahkan masukkan jumlah nominal"
            />
          </b-form-group>
          <b-form-group label="Keterangan" class="mb-3 f-13 bold">
            <b-textarea
              class="f-13"
              rows="5"
              v-model="form.data.keterangan"
              :required="!form.data.keterangan"
              placeholder="Keterangan wajib diisi"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" class="p-3">
          <router-link
            class="w-100 btn btn-secondary button-sag-cancel"
            to="/kasbon"
            >Batal</router-link
          >
        </b-col>
        <b-col cols="6" class="p-3">
          <b-button
            block
            class="w-100 button-sag"
            variant="primary"
            type="submit"
            >Ajukan</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>
<style>
.f-12 {
  font-size: 12px;
}
.bold {
  text-transform: uppercase;
  font-weight: bold;
}
.f-13 {
  font-size: 13px;
}
.button-sag {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #539165;
  border: none;
}
.button-sag:hover {
  background-color: #539165;
}
.button-sag-cancel {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #ce5959;
  border: none;
}
.button-sag-cancel:hover {
  background-color: #ce5959;
}
</style>
<script>
import { mapGetters } from "vuex";
import axios from "@/axios";
export default {
  data() {
    return {
      form: {
        data: {
          branch_name: null,
          branch_code: null,
          nik: null,
          jml_kasbon: null,
          keterangan: null,
        },
        loading: false,
      },
      opt: {},
    };
  },
  methods: {
    setUpForm() {
      let userData = this.user.data;
      this.form.data.nik = userData.nik;
      this.form.data.branch_code = userData.branch_code;
      this.form.data.branch_name = userData.branch_name;
    },
    async doSubmit() {
      this.form.loading = true;
      let url = "/presence/kasbon";
      let header = {
        headers: {
          Token: this.user.token,
        },
      };
      let payload = new FormData();
      for (let key in this.form.data) {
        payload.append(key, this.form.data[key]);
      }
      let req = await axios.post(url, payload, header);
      if (req.data.status) {
        this.form.loading = false;
        this.notif("Pengajuan Kasbon Berhasil", "Sukses", "success");
        this.clearForm();
        setTimeout(() => {
          this.$router.push("/kasbon");
        }, 2000);
      } else {
        this.form.loading = false;
        this.notif(
          "Pengajuan Kasbon Gagal (Kesalahan pada server)",
          "Gagal",
          "error"
        );
      }
    },
    clearForm() {
      this.form.data.jml_kasbon = null;
      this.form.data.keterangan = null;
    },
    notif(msg, title, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-full",
      });
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.setUpForm();
  },
};
</script>
